import { Injectable, ApplicationRef } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { CoreProvider } from './core';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { SwUpdate } from '@angular/service-worker';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class VersionServiceProvider {

  private core: CoreProvider;
  private readyUpdateNoticed: boolean = false;

  // Current version
  private desktopVersionNumberData: string = '';
  public get desktopNumber(): string {
    return this.desktopVersionNumberData;
  }
  private versionNumberData: string = '';
  public get number(): string {
    return this.versionNumberData;
  }

  // Last version
  private lastDesktopVersionNumberData: string = '';
  public get lastDesktopVersionNumber(): string {
    return this.lastDesktopVersionNumberData;
  }
  private lastVersionNumberData: string = '';
  public get lastVersionNumber(): string {
    return this.lastVersionNumberData;
  }


  // Expired version (currently unused)
  private expireTimestampData: string = '1970-01-01 00:00:00';
  public get expireTimestamp(): string {
    return this.expireTimestampData;
  }
  public get versionExpired(): boolean {
    return (!this.core)
      ? false
      : (this.updateable && (new Date())>this.core.getDateFromTimestamp(this.expireTimestampData))
    ;
  }

  // Updates
  private isDesktopUpgradeData: boolean = false;
  public get isDesktopUpgrade(): boolean {
    return this.isDesktopUpgradeData;
  }
  private updatePepared: boolean = false;
  public get updateable(): boolean {
    return this.updatePepared;
  }
  public get isMandatoryUpgrade(): boolean {
    const numberSplit = this.number.split('.');
    const lastNumberSplit = this.lastVersionNumber.split('.');
    return (numberSplit[0] != lastNumberSplit[0]) || (numberSplit[1] != lastNumberSplit[1]);
  }
  updateInterval;



  config = {
    updateIntervalDelay: 1 * 60 * 1000, // 1 minute
    autoUpdateOnInit: false,
    updateModal: null
  };

  constructor(
    private meta: Meta,
    private http: HttpClient,
    // private appVersion: AppVersion,
    private appRef: ApplicationRef,
    private pwaUpdate: SwUpdate
  ) {
    // Subscribe to pwa updates
    pwaUpdate.available.subscribe((e) => {
      console.log('UPDATE AVAILABLE, updating... ', e);
      pwaUpdate.activateUpdate().then(() => {
        console.log('UPDATE PREPARED: ', e);
        this.updatePepared = true;
        let d = new Date();
        d.setUTCDate(d.getUTCDate()+1);
        this.expireTimestampData = d.toISOString().slice(0, 19).replace('T', ' ').replace('Z', '');
        this.checkForUpdateModal();
      })
    });
  }

  public initCore(core: CoreProvider) {
    this.core = core;

    // PWA and Native
    const viewport = this.meta.getTag('name=version');
    this.versionNumberData = viewport.content;
    console.warn('VERS-PWA', this.versionNumberData)
    this.checkForUpdates(true, () => this.checkForUpdateModal());

    // this.appVersion.getVersionNumber().then(
    //   (v) => {
    //     // CORDOVA Version Plugin
    //     this.versionNumberData = v;
    //     console.warn('VERS-CORDOVA', this.versionNumberData)
    //     this.checkForUpdates(true, () => this.checkForUpdateModal());
    //   },
    //   () => {
    //     // PWA
    //     const viewport = this.meta.getTag('name=version');
    //     this.versionNumberData = viewport.content;
    //     console.warn('VERS-PWA', this.versionNumberData)
    //     this.checkForUpdates(true, () => this.checkForUpdateModal());
    //   }
    // );

    // Fetch current ah-runtime-version
    if (this.core.electron.isElectron) {
      this.desktopVersionNumberData = process.env['ah-runtime-version'];
      console.warn('VERS-AH-RUNTIME', this.desktopVersionNumberData)
    }
  }

  public getVersion(): string {
    return this.versionNumberData;
  }

  private checkForUpdates(firstSync = false, cb = null) {
    if (firstSync) {
      this.core.appIsStable.subscribe(() => this.startUpdateInterval());
      if (this.config.autoUpdateOnInit) this.update();
    }
    // PWA checks
    if (['pwa', 'mac', 'windows'].includes(this.core.device)) {
      if (this.pwaUpdate.isEnabled) this.pwaUpdate.checkForUpdate().then(() => {
        if (cb) cb();
      });
    }
    // Generic checks for all platforms
    this.http.get<any>(`${location.protocol}//${location.host}${location.port ? ':'+location.port:''}/assets/version.json`).subscribe(res => {
      const platformProp = {
        ios: 'ios_version',
        android: 'android_version',
        pwa: 'pwa_version',
        mac: 'pwa_version',
        windows: 'pwa_version',
      }[this.core.device];
      console.debug('Last version', res);

      // APP Upgrades
      this.lastVersionNumberData = res.platform[platformProp] ?? res.version;
      if (this.lastVersionNumberData != this.versionNumberData) {
        if (['ios', 'android'].includes(this.core.device)) {
          this.updatePepared = true;
        }
        if (platformProp == 'pwa_version') {
          // this.updatePepared = true; // Only debug, disable for production to wait for pwa update preparation
          console.warn('PWA app upgrade should be ready');
        }
      }

      // Desktop runtime upgrades
      if (['mac', 'windows'].includes(this.core.device)) {
        this.lastDesktopVersionNumberData = res.platform.desktop_runtime_version;
        if (this.lastDesktopVersionNumberData != this.desktopVersionNumberData) {
          this.isDesktopUpgradeData = true;
          this.updatePepared = true;
        }
      }
    });
  }

  public startUpdateInterval() {
    this.appRef.isStable.subscribe(() => {
      if (this.config.updateIntervalDelay) {
        if (this.updateInterval) clearInterval(this.updateInterval);
        this.updateInterval = setInterval(() => {
          this.checkForUpdates(false, () => this.checkForUpdateModal());
        }, this.config.updateIntervalDelay);
      }
    })
  }

  private checkForUpdateModal() {
    if (this.config.updateModal) {
      if (!this.readyUpdateNoticed && this.updateable && !this.config.updateModal.oppened) {
        this.readyUpdateNoticed = true;
        this.config.updateModal.Open(this.core);
      } else if (this.versionExpired && !this.config.updateModal.oppened) {
        this.config.updateModal.Open(this.core);
      }
    }
  }

  public stopUpdateInterval() {
    clearInterval(this.updateInterval);
  }

  public update(force: boolean = false) {
    if (force || this.updateable) {
      this.runUpdate();
    } else {
      // TODO: Handle err
    }
  }

  private runUpdate() {
    if (this.isDesktopUpgrade) {
      const ext = (this.core.device == 'mac') ? 'dmg':'exe';
      const setup = (this.core.device == 'mac') ? '':'-Setup';
      const arch = (this.core.device == 'mac' && process.arch == 'arm64') ? '-arm64':'';
      const url = `https://images.handball.ai/Handball.AI${setup}-${this.lastDesktopVersionNumber}${arch}.${ext}`;
      this.core.electron.ipcRenderer.send('new-version', url);
    } else if (this.core.device == 'pwa') {
      location.reload();
    } else if (this.core.device == 'ios') {
      window.open('https://apps.apple.com/app/handball-ai/id1603618186', '_blank').focus();
    } else if (this.core.device == 'android') {
      window.open('https://play.google.com/store/apps/details?id=ai.handball.app', '_blank').focus();
    } else { // Desktop (mac, windows)
      this.core.electron.ipcRenderer.send('reload');
    }
  }

  public getFullVersionString() {
    return this.core.device+'_v'+this.versionNumberData;
  }

}
