import { ParentSpecific, Specific } from "./specific/base-specific";
import { genericFactory, genericTlFactory, TlTypes } from "./specific/factory";

interface IConstructor<T> {
    new (...args: any[]): T;

    // Or enforce default constructor
    // new (): T;
}

// -- Interface Implementations -- //

export interface IBitpassData<S extends ParentSpecific<S>|Specific> {
    _proto: 'ICON' |'META'|'TREE'|'KEYVAL'|'COMMENTS'|'JSON';
    title: string;
    val: IBitpassIconDataVal<S>;
}

export interface IBitpassIconDataVal<S extends ParentSpecific<S>|Specific> {
    title: string;
    description: string;
    background: string;
    aux: string;
    media: IBitpassIconMedia[];
    'pass.json': IPkpassJsonData;
    passImages: IPkpassMedia;
    schemaVersion: number;
    icon: string;
    summary: IBitpass;
    claimHash: string;
    updated: number;
    soldInfo: IBitpassSoldInfo;
    distributionIdentities: string[];
    specific: ParentSpecific<S>|Specific; //ParentSpecific<S>|
}

export interface IBitpassIconMedia {
    type: 'image'|'pkpass'|'video'|'file';
    url: string;
    label: string;
}
export interface IBitpass {
    title: string;
    cards: IBitpassCard[];
}
export interface IBitpassSoldInfo {
    activeSold: null|IBitpassActiveSold;
    addressSeller: string;
    chain: string;
    networkFee: number;
    oldID: string;
    price: number;
    workerFee: number;
}
export interface IBitpassActiveSold {
    address: string;
    candidateID: string;
    initTimestamp: number;
}

export interface IBitpassCard {
    // style: 'title'|'description'|'action'|'details'|'comments'|'childs';
    style: 'card_Title' | 'card_dustPass' | 'card_microPass' | 'card_miniPass' | 'card_macroPass';
    title: string|null;
    cardBtn: IBitpassCardBtn;
    cardImg: IBitpassCardImg|null;
    cardElements?: IBitpassCardElement[];
    endLink?: IBitpassCardEndLink|null;
    description: string;
    collapsed: boolean
}
export interface IBitpassCardImg {
    style: 'miniature'|null;
    url: string;
}
export interface IBitpassCardBtn {
    style: 'btn_1'|null;
    text: string;
    url: string;
}

export interface IBitpassCardText {
    value: string;
    type: string; // String, Number or Percentage
    required: boolean;
}

export interface IBitpassCardBtml {
    btml: string;
    editable: boolean;
}

export interface IBitpassCardElement {
    label?: string;
    icon?: string;
    textCard?: IBitpassCardText;
    image?: string;
    btmlCard?: IBitpassCardBtml;
}
export interface IBitpassCardEndLink {
    label: string;
    url: string;
}
export interface IPkpassJsonData {
    organizationName: string;
    logoText: string;
    headerFields: IPkpassJsonField[];
    primaryFields: IPkpassJsonField[];
    secondaryFields: IPkpassJsonField[];
    auxiliaryFields: IPkpassJsonField[];
    backFields: IPkpassJsonField[];
    foregroundColor: string;
    backgroundColor: string;
}
export interface IPkpassJsonField {
    key: string;
    label: string;
    value: string;
}
export interface IPkpassMedia {
    'logo.png': string;
    'logo@2x.png'?: string;
    'logo@3x.png'?: string;
    'background.png': string;
    'background@2x.png'?: string;
    'background@3x.png'?: string;
    'strip.png': string;
    'strip@2x.png'?: string;
    'strip@3x.png'?: string;
    'footer.png': string;
    'footer@2x.png'?: string;
    'footer@3x.png'?: string;
    'thumbnail.png': string;
    'thumbnail@2x.png'?: string;
    'thumbnail@3x.png'?: string;
    'icon.png': string;
    'icon@2x.png'?: string;
    'icon@3x.png'?: string;
}

// -- Class Implementations -- //

export class BitpassData<S extends ParentSpecific<S>|Specific> implements IBitpassData<S> {
    _proto: 'ICON' |'META'|'TREE'|'KEYVAL'|'COMMENTS'|'JSON' = 'ICON';
    title: string = '';
    val: BitpassIconDataVal<S>;

    constructor(data: IBitpassData<S>, sCtor: IConstructor<S>) {
        for(const k in this) this[k] = data[k as never];
        this.val = new BitpassIconDataVal(data.val, sCtor);
    }
}
export class BitpassIconDataVal<S extends ParentSpecific<S>|Specific> implements IBitpassIconDataVal<S> {
    title: string = '';
    description: string = '';
    background: string = '';
    aux: string = '';
    media: BitpassIconMedia[] = [];
    'pass.json': PkpassJsonData = null;
    passImages: PkpassMedia = {
        'logo.png': null,
        'background.png': null,
        'strip.png': null,
        'footer.png': null,
        'thumbnail.png': null,
        'icon.png': null,
    };
    schemaVersion: number = 0;
    icon: string = '';
    // TODO: Implement class for interface
    summary: IBitpass = null;
    claimHash: string = null;
    distributionIdentities: string[] = [];
    soldInfo: IBitpassSoldInfo = null;
    updated: number = 0;
    specific: S = null;

    constructor(data: IBitpassIconDataVal<S>, sCtor: IConstructor<S>) {
        for(const k in this) this[k] = data[k as never];
        this['pass.json'] = new PkpassJsonData(data['pass.json']);
        this.media = data.media.map(i => new BitpassIconMedia(i));
        if (!this.distributionIdentities) this.distributionIdentities = [];
        this.specific = sCtor ? new sCtor(data.specific):genericTlFactory(data.specific as TlTypes);
    }
}
export class BitpassIconMedia implements IBitpassIconMedia {
    type: "image" | "pkpass" | "video" | "file" = 'image';
    url: string = '';
    label: string= '';

    constructor(data: IBitpassIconMedia) {
        for(const k in this) this[k] = data[k as never];
    }
}
export class PkpassJsonData implements IPkpassJsonData {
    organizationName: string = '';
    logoText: string = '';
    headerFields: PkpassJsonField[] = [];
    primaryFields: PkpassJsonField[] = [];
    secondaryFields: PkpassJsonField[] = [];
    auxiliaryFields: PkpassJsonField[] = [];
    backFields: PkpassJsonField[] = [];
    foregroundColor: string = '';
    backgroundColor: string = '';

    constructor(data: IPkpassJsonData) {
        for(const k in this) this[k] = data[k as never];
    }
}
export class PkpassJsonField implements IPkpassJsonField {
    key: string = '';
    label: string = '';
    value: string = '';

    constructor(data: IPkpassJsonField) {
        for(const k in this) this[k] = data[k as never];
    }
}
export class PkpassMedia implements IPkpassMedia {
    'logo.png': string = '';
    'background.png': string = '';
    'strip.png': string = '';
    'footer.png': string = '';
    'thumbnail.png': string = '';
    'icon.png': string = '';

    'logo@2x.png'?: string;
    'background@2x.png'?: string;
    'strip@2x.png'?: string;
    'footer@2x.png'?: string;
    'thumbnail@2x.png'?: string;
    'icon@2x.png'?: string;

    'icon@3x.png'?: string;
    'logo@3x.png'?: string;
    'strip@3x.png'?: string;
    'background@3x.png'?: string;
    'thumbnail@3x.png'?: string;
    'footer@3x.png'?: string;

    constructor(data: IPkpassMedia) {
        for(const k in this) this[k] = data[k as never];
    }
}
