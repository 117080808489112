import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class ApiAuthInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(this.addAuthToken(request));
  }

  addAuthToken(request: HttpRequest<any>) {
    const browserId = environment.browser_id;
    const token = environment.auth_token
      ? { Authorization: `Bearer ${environment.auth_token}` }
      : {};

    return request.clone({
      url: request.url.replace('RUNTIME_VALUE', environment.current_relay.replace(/\/$/g, '')),
      setHeaders: {
        ...token,
        'x-browser-id': browserId,
      },
    });
  }
}
